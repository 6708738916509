import wellbeingContent from "../content/wellbeing";
import Header from "../components/header";
import SliderAnswer from "../components/sliderAnswer";
import { useNavigate } from "react-router-dom";
import Button from "../atoms/button";
import Checkbox from "../atoms/checkbox";
import { useEffect, useState } from "react";
import { getUsabilityQuestion, postUsersResponses } from "../functions/usability";



const Wellbeing = ({ session }) => {
    const [canContinue, setCanContinue] = useState( false );
    const [hasPlan, setHasPlan] = useState( false );
    const [hasPerson, setHasPerson] = useState( false );
    const [hasMood, setHasMood] = useState( false );
    const [usabilityQuestions, setUsabilityQuestions] = useState({ questions:[], responses: [] });
    const [userResponses, setUserResponses] = useState([]);


    useEffect(() => {
        if(session !== null && session.surveyId !== undefined){
            getUsabilityQuestion(session.surveyId, 2 , setUsabilityQuestions);
        }
      },[session])

    useEffect(() => {
        setCanContinue((hasPlan && hasPerson && hasMood));
    },[hasPlan, hasPerson, hasMood])


    const navigate = useNavigate();

    const proceedOnclick = (e) => {
        e.preventDefault();
        postUsersResponses({session: session.sessionId,responses: userResponses}, (success)=>{ 
            if(success === true) {
                navigate("/instructions");  
            } else {
                console.log("failed to save demos");
            }
        });  
    } 

    const handlePlanChange = (e) => {
        setHasPlan(e.target.checked)
    }

    const handlePersonChange = (e) => {
        setHasPerson(e.target.checked)
    }

    const renderProceedButton = () => {
        if (canContinue) {
            return (
                <Button handleOnClick={ proceedOnclick } path="/instructions" label="Continue" />
            )
        }
        return (
            <Button disabled={ true } label="Continue" />
        )
    }

    const responseOnChange = (val, id) => {
        let ur = userResponses;
        let thisResponse = { question: id, response: val.value, freetext: (val.freetext === undefined) ? "" : val.freetext }

        let hasQ = false;

        for (let i = 0 ; i < ur.length ; i ++) {
            if (ur[i].question === thisResponse.question) { 
                hasQ = true;
            }
        }
        
        if (!hasQ) {
            ur.push(thisResponse)
        } else {
            for (let i = 0 ; i < ur.length ; i ++) {
                if (ur[i].question === thisResponse.question) { 
                    ur[i] = thisResponse;
                }
            }
        }
        setHasMood(true);
        setUserResponses(ur);
        }


    const getResponses = (responseTypeId) => {
        let responses = [];
        for (let i = 0; i < usabilityQuestions.responses.length; i ++) {
            if (responseTypeId === usabilityQuestions.responses[i].responseTypeId) {
                responses.push(usabilityQuestions.responses[i]);
            }
        }
        return responses;
    }

    const renderQuestions = () => {
        if (!('questions' in usabilityQuestions)) return;
        if (usabilityQuestions.questions == undefined || usabilityQuestions.questions.length === 0 ) return;
        return (
            usabilityQuestions.questions.map((question) =>
            <SliderAnswer 
                id={question.usabilityId}
                question={question.question}
                callback={responseOnChange}
                responses={getResponses(question.responseTypeId)}
                leftLabel={String.fromCodePoint("128546")}
                rightLabel={String.fromCodePoint("128512")}
                altColour="even"
                />

            )
        )
    }

    return (
        <div className="App">
            <Header title={wellbeingContent.title}/>
            <div className="content">
                <p>Because this study requires you to think about your self-harm, we want to ensure participants are supported during the study. </p>
                <p>Before you can begin, please rate your current mood on the scale below by selecting a number between 1-10.</p>
                <div className="sliderContainer">
                    {renderQuestions()}
                </div>

                <div>
                    <p><b>What do you plan to do after the study?</b><br/> It can be helpful to plan to do something you enjoy doing after taking part in research sessions. This could be on your own or with a trusted person.</p> 
                    <Checkbox onChange={handlePlanChange} statement="Please check this box to confirm you have written down your plan." />
                    <p><b>Please write down the contact details of a trusted person.</b><br/> Ensure you also have their details to hand so you can easily contact them during or after the study should you feel any distress or concern. (Please note, we will not save this information)</p>
                    <Checkbox onChange={handlePersonChange} statement="Please check this box to confirm you have written down the details of a trusted person." />
                </div>
            </div>
            <div className="demoFtr">
                    {renderProceedButton()}
            </div>
    </div>

    );

  };
  
  export default Wellbeing;