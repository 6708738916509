import React, { useState, useEffect } from 'react';
import storeKeys from "../data/storeKeys";
import { endSession } from "../functions/session";
import Header from "../components/header";
import thankyouContent from '../content/thankyou';



const Complete = ({ session }) => {
    const [sessionId, setSessionId] = useState( "" );
    const [sessionEnded, setSessionEnded] = useState( false );

    useEffect(() => {
      if(session !== null && !sessionEnded) {
        window.localStorage.setItem(storeKeys.CurrentCard, "-");
        setSessionEnded(true)
        setSessionId(session.sessionId)
        endSession(session.sessionId);
      }
    },[sessionEnded,session])

    return (
    <div>
        <Header title={thankyouContent.title}/>
        <div className="contentBlock">{thankyouContent.thankyouStatement}</div>
        <div className="contentBlock">{thankyouContent.objectiveStatement}</div>
        <div className="contentBlock" dangerouslySetInnerHTML={{ __html: thankyouContent.sessionStatement.replace("[[sessionID]]", sessionId )}}></div>
        <div className="contentBlock"dangerouslySetInnerHTML={{ __html: thankyouContent.dataStatement}}></div>       
        <div className="contentBlock"dangerouslySetInnerHTML={{ __html: thankyouContent.supportStatement}}></div>       
    </div>
    );
  };
  
  export default Complete;