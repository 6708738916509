import React from "react";
import Answer from "../atoms/answer";

const Answers = ( { answers, answerType, selectedAnswers, handleAnswerClick } ) => {
    const rendorAnswer = (answer) => {   
        if (answer.type === "default" || answer.type === answerType)
        {
            let selectedAnswer = selectedAnswers.includes(answer.id);
            let classes = (selectedAnswer) ? "answer answerSelected" : "answer";
            return (
                <Answer key={answer.id} id={answer.id} label={answer.label} classes={classes} clickHandler={handleAnswerClick} />
            )
        }
    }

    return (
    <div className="answers">
        {answers.map((answer) => (rendorAnswer(answer)))}
    </div>
    )
}

export default Answers;