let infoContent = {
    "title" : "Data Privacy",
    "gdprStatement" : "Our survey is 100% anonymous and we do not collect personally identifiable information",
    "faqs" : [
        {"title" : "What are my data rights?", "body" : "Under General Data Protection Regulations (GDPR), you have the right to access and delete any data collected on this survey."}
    ],
    "sessionStatement" : "We will store your data anonymously and confidentially on University of Nottingham servers. If you wish to withdraw your data, please email <a href='mailto:katherine.bird@nottingham.ac.uk'>katherine.bird@nottingham.ac.uk</a> and quote this unique identifying number: <p style='text-align:center'><b>[[sessionID]]</b></p> ",
    "btnLabel" : "I am happy to continue"
};

export default infoContent;