import { Link } from "react-router-dom";

const Button = ( {path, handleOnClick, label, customClass, disabled } ) => {
    const isDisabled = !(disabled === undefined || disabled === false);
    let classes = isDisabled ? "disabled button" : "button" ;

    const renderLink = () => {
        if (customClass != null) classes = classes + " " + customClass;

        if (isDisabled) {
            return (
                <Link className={classes}>{label}</Link>
                )
        }
        return (
            <Link className={classes} onClick={handleOnClick} to={path}>{label}</Link>
        )

    } 

    return ( renderLink() );
}

export default Button;
