let homeContent = {
    "title" : "Card Sort Task for Self-Harm",
    "intro" : `We invite you to take part in this online study which examines factors which may lead to self-harm.
        This study is open for participants aged 18 and over, who reside in the UK, and have a history of self-harm (self-injury or -poisoning regardless of suicidal intent).
        You can complete the study on a smartphone, tablet, or laptop/desktop.
        The study will take approximately 20-minutes. 
                `,
    "participation" : `
        Participation is completely voluntary, and you can leave the study at any time by closing the browser.
        The study data is completely anonymous and will be stored securely on a University of Nottingham Azure server during recruitment.
        Once data is analysed, the original files will be deleted.
        You will be given a unique ID at the beginning of the study, take note of this as you will need it if you want to withdraw your data.
        You can withdraw your data up to one week after taking part by emailing <a href="mailto:Katherine.bird@nottingham.ac.uk">Katherine.bird@nottingham.ac.uk</a>. 
    `,

    "risk" : `
        The study asks you to think about your last self-harm episode and whether factors occurred at specific times before your last self-harm episode.
        The study presents cards with factors on them, and some of these explicitly mention physical and sexual violence, abuse, and suicide.
        The cards are presented randomly, so please consider your current wellbeing and whether you feel able to take part in the study.
        If you are in distress, contact a trusted person or appropriate organisation.
        Contact your trusted person ahead of time to ensure they are available during your participation in the study.
                                
        <p><b>Call 999 if you are in immediate crisis.</b></p>
        
        Here are some support organisations:
        <ul>
            <li>Harmless (harmless.org.uk) 0115 880 0280</li>
            <li>Text SHOUT to 85258 for free</li>
            <li>Text support or freecall The Samaritans 116 123</li>
            <li>LGBT Hero (www.lgbthero.org.uk) 0300 330 0630</li>
        </ul>
        These will be provided again at the end of the study.
    `,
    "instructions" : `
        <ul>
            <li>Please think about the <b>last time</b> you self-harmed.</li>
            <li>You will be presented with a card with a self-harm factor on it.</li>
            <li>If the card is relevant to your last self-harm episode, select <b>all</b> relevant timepoints the card relates to.</li>
            <li>You can select all timepoints if relevant to your self-harm.</li>
            <li>If the card relates to what was happening or what you were feeling when you self-harmed, you can also select 'I self-harmed'.</li>
            <li>If the card is not relevant, select 'not applicable'.</li>
            <li>Press 'next' when you are ready.</li>
            <li>You will work through each card in the same way until the task is complete.</li>
        This should take about 20-minutes.
    `,
    "declaration" : `
        The research team declare no conflicts of interest with regards to this study or publications which may arise from this study.
    `,
    "faqs" : [
        {"title" : "Supporting development of online resources", "body": "This online study provides participants the opportunity to guide the development of a web-app version of a card sort task for self-harm (CaTS-E)."},
        {"title" : "Supporting research into self-harm", "body": "CaTS-E allows people to describe their pathway to self-harm using cards with events, behaviours, feelings, and thoughts on them. We are developing CaTS-E to use in future research where we will capture data from large numbers of participants to identify key tipping points for self-harm. Before this, we need to ensure CaTS-E works on smartphones and devices in a way that is easy for users. We need to ensure there are no glitches or confusing instructions. We also need to ensure cards are relevant to people who self-harm. This pilot study will ensure CaTS-E is feasible and acceptable to participants, and ensure it is user-friendly. These are really important to ensure CaTS-E is useful for future research."},
        {"title" : "What will happen if I take part?", "body" : "You need to complete CaTS-E using your smartphone, tablet, or desktop. You will be shown a card with a thought, feeling, behaviour, or event related to self-harm on it. Underneath each card will be timepoints (from 6-months prior, to I self-harmed, then immediately afterwards). You should select all the timepoints that card is relevant to. There is also a ‘not relevant’ option for cards not relevant to your self-harm. You will move through the cards this way until you have gone through all the cards. Afterwards, you will be asked some questions about performing the task. Questions will be about the performance of the web-app and ease of use. You will also be asked about the concepts written on cards."},
        {"title" : "How long will the survey take?", "body" : "The study will last approximately 20-minutes."},
        {"title" : "Whats in it for me?", "body" : "You will receive a £10 Amazon voucher to thank you for taking part. Please email the lead researcher (<a href='mailto:katherine.bird@nottingham.ac.uk'>katherine.bird@nottingham.ac.uk</a>) to confirm you have taken part and the voucher will be emailed to you. We will not be able to link your responses to your contact details."},
        {"title" : "Will I be asked specific questions about my self harm?" , "body" : "<p>Performing CaTS-E requires you to think about your self-harm and factors relating to it. Some people find the experience therapeutic, but it may cause some people distress. Some of the concepts on the cards are distressing. They may not relate to your self-harm, but may still cause distress (e.g., “Someone I knew killed themselves”). Before starting, please consider your current wellbeing and ensure it is safe for you to take part. If you are in distress, please contact a trusted person or appropriate organisation. Contact your trusted person ahead of time to ensure they are available during the time you participate.</p><p><b>Call 999 if you feel in immediate crisis</b></p><p>Here are some support organisations: <ul><li>Harmless (harmless.org.uk) 0115 880 0280</li><li>Text SHOUT to 85258 for free</li><li>text support from The Samaritans or freecall 116 123</li><li>LGBT Hero (www.lgbthero.org.uk) 0300 330 0630</li><li>Self-Injury Support (selfinjurysupport.org.uk)</li></ul>These details will also be provided at the end of the study.</p>"},
        {"title" : "What happens with my data?", "body" : "Your data will be stored securely and anonymously on a University of Nottingham server. You can withdraw your data up to 1 week after taking part by emailing the lead researcher (<a href='mailto:katherine.bird@nottingham.ac.uk'>katherine.bird@nottingham.ac.uk</a>) and quote your unique identifier. After this time analysis will be underway and withdrawal will not be possible. Once data is analysed, the original files will be deleted."},
        {"title" : "What happens if I no longer want to participate?" , "body" : "You are under no obligation to take part. If you do not wish to take part, or you wish to withdraw at any time during the study, please close the browser window."},
        {"title" : "Who do I contact if I need more information?", "body" : "If you would like more information, please contact: <a href='mailto:katherine.bird@nottingham.ac.uk'>katherine.bird@nottingham.ac.uk</a>."},
    ],

    "consent" : `Ethical approval was granted by The Ethics Committee at the School of Psychology, University of Nottingham (Approval code: S1543R).
        This study was funded by a grant from Mental Health Research UK.
        To confirm you have read and understood the above information, please click the 'Begin Study' button to provide informed consent ('Begin study' button here):
    `,    
    "startBtnLabel" : "Begin Study",
};

export default homeContent;