
const Card = ( {id, statement, category, colour} ) => {
    if (statement === "") {
        statement = "Loading Statements..."
    }
    const renderCategoryTitle = () => {
        let cat = "";
        if (category.id==="Afterwards") cat = category.description;
        return (
            <div className="cardCategory">{cat}</div>
        )
    }

    return (
        <div id={ id } className={ "card" }  style={{borderColor:colour}}>
            {renderCategoryTitle()}
            <div className="statement">{ statement }</div>
        </div>
    )
};


export default Card;
