import { useState } from "react";

const YesNoAnswer = ( { id, question, responses, callback, altColour } ) => {
    const [lastYesNo, setLastYesNo] = useState();
    const [freetextDisabled, setFreetextDisabled] = useState(true);
    const [freetextValue, setFreetextValue] = useState("");

    const onChangeHandler = (e) => {
        if (e.target.type === "textarea") {
            setFreetextValue(e.target.value)
            callback({ value : lastYesNo, freetext: e.target.value }, id);
        }
        else {
            let val = e.target.value.split("-");
            setLastYesNo(val[0]);
            if (val[2] === "false") setFreetextDisabled(true);
            if (val[2] === "true") setFreetextDisabled(false);
            callbackHandler( e.target.type, val[0] )
        }
    }

    const callbackHandler = (type, val) => {
        callback({ value : val, freetext: freetextValue }, id);
    }

    const renderRadios = () => {
        return (
            <div className="yesNoRadios">
            { responses.map((response) => (
                <label>
                    <input type="radio" name={ "yesno-" + id } value={response.value + "-" + response.text + "-" + response.hasFreetext} onChange={onChangeHandler} />
                    { response.text }
                </label>
            ) ) }
            </div>
        )
    }

    return (
        <div className={"yesNoAnswerContainer usabilityQuestion " + altColour} >
            <div className="question">{question}</div>
            <div className="yesNoAnswer">
            {renderRadios()}
            <div className={(freetextDisabled)? "ifNoText disabled" : "ifNoText"}>
                Please give brief feedback (insert free text box, maybe 150 words?)
            </div>
            <textarea 
                className={(freetextDisabled)? "freetext disabled" : "freetext"}
                id={"yn-feedback-" + id}
                onChange={onChangeHandler}

                disabled={(freetextDisabled)? "disabled" : ""}
            />
            </div>
        </div>
    )
}

export default YesNoAnswer;