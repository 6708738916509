import Button from "../atoms/button";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Select from 'react-select';

import { getDemographicOptions, postDemographicResponses } from "../functions/demographics";
import aboutYouContent from "../content/aboutYou";
import Header from "../components/header";


const AboutYou = ({ session }) => {

    const navigate = useNavigate();

    const [canContinue, setCanContinue] = useState(false);

    const [demographics, setDemographics] = useState( [] );
    const [selections, setSelections] = useState( [] );
 
    useEffect(() => {
        if(session !== null && session.surveyId !== undefined){
            getDemographicOptions(session.surveyId, updateDemographicOptions);
        }
    },[session])
 
    const updateDemographicOptions = (data) => {
        setDemographics(data);
    }

    const proceedOnclick = (e) => {
        e.preventDefault();
        if (canContinue === true) {
            if(selections.length > 0 )
            {
                postDemographicResponses({session: session.sessionId,responses: selections}, (success)=>{ 
                    if(success === true) {
                        navigate("/wellbeing") 
                    } else {
                        console.log("failed to save demos");
                    }
                });    
            }
        }
    }

    const handleSelection = e => {
        const selection = e.value.split("-")
        let newSelections = [];
        for(let i = 0 ; i < selections.length; i++) {
            let saved = selections[i].split("-");
            if (saved[0] !== selection[0]) {newSelections.push(selections[i])}
        }
        newSelections.push(e.value);
        setSelections(newSelections);
        if(newSelections.length > 0 ) setCanContinue(true);

    }

    const renderProceedButton = () => {
        let classes = "proceed disabled"
        if (canContinue === true) classes = "proceed";

        return (
            <Button customClass={classes} handleOnClick={ proceedOnclick } path="/wellbeing" label="Continue" />
        )
    }
    
    return (
        <div className="App">
            <Header title={aboutYouContent.title}/>
            <div className="content">
                <div className="contentBlock">{aboutYouContent.why}</div>
                <div className="contentBlock">
                { demographics.map((group) => 
                    <div key={group.id} className="formField">
                        <div className="demoLabel">{group.label}</div>
                        <Select id={"d-"+group.id} onChange={handleSelection} className="select" options={group.options} />
                    </div>
                )}
                </div>
                <div className="demoFtr">
                    {renderProceedButton()}
                </div>
            </div>
    </div>

    );

  };
  
  export default AboutYou;