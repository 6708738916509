import React from "react";
import Likert from 'react-likert-scale';

const SliderAnswer = ( { id, question, responses, leftLabel, rightLabel, callback, altColour } ) => {

    const onChangeHandler = (val) => {
        callback(val, id);
    }

    const options = {
        id: "slider-" + id,
        responses: responses,
        onChange: onChangeHandler
    }
    
    return (
        <div className={"sliderContainer usabilityQuestion " + altColour}>
            <div className="question">{question}</div>
            <div className="sliderAnswer">
                <div className="sliderEnds">{leftLabel}</div>
                <div>
                    <Likert {...options}/>
                </div>
                <div className="sliderEnds">{rightLabel}</div>
            </div>
        </div>
    )
}

export default SliderAnswer;