import React, { useState, useEffect } from 'react';

import Header from "../components/header";
import { checkHealth } from "../functions/healthcheck";

const PlatformHealth = ( { session } ) => {

  const [healthData, setHealthData] = useState( false );
  
  console.log(session)

    useEffect(() => {
      if(session !== null && session.surveyId !== undefined){
        checkHealth(session.surveyId, setHealthData);
      }
    },[session])

    const renderHealthTable = () => {
      if (!healthData) return ("No health data");
      return (
        <table border={1} cellPadding={10} width={"100%"} style={{marginTop:"10px"}}>
          <tr><th style={{textAlign:"left"}}>Survey</th><td>{healthData.survey.key}</td></tr>
          <tr><th style={{textAlign:"left"}}>Total Sessions</th><td>{healthData.sessions.total}</td></tr>
          <tr><th style={{textAlign:"left"}}>Completed Sessions</th><td>{healthData.sessions.completed}</td></tr>
        </table>
      )
    }

    return (
      <div className="content">
        <Header title="CaTS-e Health" />
        {renderHealthTable()}
      </div>
    );
  };
  
  export default PlatformHealth;