let infoContent = {
    "title" : "Thank You",
    "thankyouStatement" : "Thank you for taking part in this study. We hope taking part in this study has not upset you. Previous research shows recalling happy memories and viewing pictures of cute animals can improve mood after taking part in research. ",
    "objectiveStatement" : `
        The purpose of this study was to identify key risk and protective factors which lead to self-harm. 
        Identifying which factors result in self-harm and when they occur is important because they may be useful during intervention.
        The results of this study will also identify whether certain people (i.e., age, gender identity, ethnicity) are affected by specific events, behaviours, or feelings.
        This is important to provide appropriate support to people at risk of self-harm.
    `,
    "sessionStatement" : 'Here is your personal identifier: <p><b>[[sessionID]]</b></p> Email and quote this number if you wish to withdraw your data or if you have any questions or concerns about this study, please contact <b><a href="mailto:katherine.bird@nottingham.ac.uk">katherine.bird@nottingham.ac.uk</a></b>',
    "dataStatement" : '',
    "supportStatement" : `
        If you feel distressed as a result of taking part in this study, please contact your trusted person or a support organisation. 
        <p><b>Call 999 if you are in immediate crisis.</b></p>
        <ul style="font-size:12px">
            <li>Samaritans: 116 123.  https://www.samaritans.org/ </li>
            <li>Self-Injury Support: 0808 800 8088. www.selfinjurysupport.org.uk </li>
            <li>Allsorts Youth Project: 01273 72110   https://www.allsortsyouth.org.uk/ </li>
            <li>Harmless: www.harmless.org.uk</li>
            <li>Suicide Prevention UK: 0800 689 5652. www.spuk.org.uk</li>
        </ul>
 
        <p style="font-size:10px; font-style:italic">If you would like to be informed of future studies, please email katherine.bird@nottingham.ac.uk you will be added to our email list and be emailed about future self-harm studies. You are under no obligation to do this or take part in future studies. This is simply to make you aware of studies you may wish to take part in at your discretion. 
`
};

export default infoContent;