import Header from "../components/header";

const NoPage = () => {
    return (
      <div className="content">
        <Header title="Oops..." />
        
        <div className="contentBlock">The page you are looking for doesn't exist.</div>
      </div>
    );
  };
  
  export default NoPage;