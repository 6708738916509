import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState,useEffect, useRef } from 'react';
import { isMobile, isTablet } from "react-device-detect";

import { initialiseSurvey, getSessionObj, createSessionCookie, checkClearSession } from "./functions/session";

import Layout from "./pages/layout";
import NoPage from "./pages/404";
import Home from "./pages/home";
import Survey from "./pages/survey";
import Complete from "./pages/complete";
import AboutYou from "./pages/aboutyou";
import Wellbeing from "./pages/wellbeing";
import Information from "./pages/information";
import Instructions from "./pages/instructions";
import Usability from "./pages/usability";
import PlatformHealth from "./pages/platform-health";


function App() {

  const initialise = useRef(true);
  const calledInitialise = useRef(false);

  const [session, setSession] = useState( null );

  useEffect(() => {
    if (initialise.current) {
      checkClearSession(); 

      const queryParams = new URLSearchParams(window.location.search);
      let healthSurvey = queryParams.get('forceSurvey');
      //console.log(healthSurvey)
      if (healthSurvey == undefined || healthSurvey === null) {
        initialise.current = false;
        let s = getSessionObj();
        setSession(s);
      } else {
        setSession({surveyId: healthSurvey});
      }
    }

  },[]);

  useEffect(() => {
    if (!calledInitialise.current) {
      if (session !== null && !session.success){
        calledInitialise.current = initialiseSurvey(setNewSession, isMobile, isTablet);
      }
    }
  },[session]);

  const setNewSession = (data)=> {
    console.log("Set new session", data)
    setSession(data);
    if (data.success === true) {
      createSessionCookie(data);
    }
  }

  return (
      <BrowserRouter >
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home session={session} />} />
            <Route path="info" element={<Information session={session} />} />
            <Route path="about-you" element={<AboutYou session={session} />} />
            <Route path="wellbeing" element={<Wellbeing session={session} />} />
            <Route path="instructions" element={<Instructions session={session} />} />
            <Route path="survey" element={<Survey session={session} />} />
            <Route path="usability" element={<Usability session={session} />} />
            <Route path="complete" element={<Complete session={session} />} />
            <Route path="platform-health" element={<PlatformHealth session={session} />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;