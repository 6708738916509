import React from "react";
import { ReactComponent as Logo } from '../assets/nu-logo-full.svg';


const Header = ({title}) => {
    return (
    <div className="header">
        <div className="banner"> 
            <h1>{title}</h1>
            <div class="image" >
                <Logo width="80" height="30" />
            </div>
        </div>
    </div>
    )
}

export default Header;