import { API_URL } from "./apiHelper.js";

export async function getSurveyData(surveyId, passBack) {

    const response = await fetch( API_URL( '/survey/' + surveyId ));
    const data = await response.json();

    let surveyData = {cards: [], answers: [], categories: []}
    if(data !== undefined) {
        
        surveyData.cards = data.body.statements;
        surveyData.answers = data.body.answers;
        surveyData.categories = data.body.categories;
    }
    passBack(surveyData);
}