import usabilityContent from "../content/usability";
import Header from "../components/header";
import SliderAnswer from "../components/sliderAnswer";
import YesNoAnswer from "../components/yesnoAnswer";
import { useNavigate } from "react-router-dom";
import Button from "../atoms/button";
import { getUsabilityQuestionaire, postUsersResponses } from "../functions/usability";
import { useEffect, useState } from "react";

const Usability = ({ session }) => {
    const navigate = useNavigate();
    const [usabilityQuestions, setUsabilityQuestions] = useState({ questions:[], responses: [] });
    const [userResponses, setUserResponses] = useState([]);
    const [canContinue, setCanContinue] = useState( false );
    const [hasMood, setHasMood] = useState( false );


    useEffect(() => {
        if(session !== null && session.surveyId !== undefined){
            getUsabilityQuestionaire(session.surveyId, setUsabilityQuestions);
        }
      },[session])

    useEffect(() => {
        setCanContinue((hasMood));
    },[hasMood])

    const proceedOnclick = (e) => {
        e.preventDefault();

        if(userResponses.length > 0 )
        {
            postUsersResponses({session: session.sessionId,responses: userResponses}, (success)=>{ 
                if(success === true) {
                    navigate("/complete");  
                } else {
                    console.log("failed to save demos");
                }
            });    
        }
        
    }

    const renderProceedButton = () => {
        if (canContinue) {
            return (
                <Button handleOnClick={ proceedOnclick } path="/complete" label="Continue" />
            )
        }
        return (
            <Button disabled={ true } label="Continue" />
        )
    }

    const responseOnChange = (val, id) => {
        let ur = userResponses;
        let thisResponse = { question: id, response: val.value, freetext: (val.freetext === undefined) ? "" : val.freetext }

        for (let i = 0 ; i < usabilityQuestions.questions.length ; i ++) {
            if (id === usabilityQuestions.questions[i].usabilityId && usabilityQuestions.questions[i].responseType === "mood") { 
                setHasMood(true);
                break;
            }
        }    
        
        let hasQ = false;

        for (let i = 0 ; i < ur.length ; i ++) {
            if (ur[i].question === thisResponse.question) { 
                hasQ = true;
            }
        }
        
        if (!hasQ) {
            ur.push(thisResponse)
        } else {
            for (let i = 0 ; i < ur.length ; i ++) {
                if (ur[i].question === thisResponse.question) { 
                    ur[i] = thisResponse;
                }
            }
        }
        setUserResponses(ur);
    }

    const getResponses = (responseType) => {
        let responses = [];
        for (let i = 0; i < usabilityQuestions.responses.length; i ++) {
            if (responseType === usabilityQuestions.responses[i].responseTypeId) {
                responses.push(usabilityQuestions.responses[i]);
            }
        }
        return responses;
    }

    const renderSlider = (question, smileys, altColour) => {
        return (
            <SliderAnswer 
                id={question.usabilityId}
                question={question.question}
                callback={responseOnChange}
                responses={getResponses(question.responseTypeId)}
                leftLabel={String.fromCodePoint(smileys[0])}
                rightLabel={String.fromCodePoint(smileys[1])}
                altColour= {altColour}
                />
        )
    }

    const renderYesNo = (question, altColour) => {
        return (
            <YesNoAnswer 
                id={question.usabilityId}
                question={question.question}
                responses={getResponses(question.responseTypeId)}
                callback={responseOnChange}
                altColour= {altColour}
                /> 
        )
    }

    const renderQuestions = () => {
        let questionsUI = [];
        for (let i = 0 ; i < usabilityQuestions.questions.length ; i++)
        {
            let question = usabilityQuestions.questions[i];
            let altColour = "odd";
            if (i % 2 === 0) { altColour = "even" }
            switch( question.responseType ) {
                case "mood" :
                    questionsUI.push(renderSlider(question,["128546", "128512"], altColour))
                    break;
                case "ease" :
                    questionsUI.push(renderSlider(question,["128546", "128512"], altColour))
                    break;
                case "agreement" :
                    questionsUI.push(renderSlider(question,["128078", "128077"], altColour))
                    break;
                case "yesno" : 
                    questionsUI.push(renderYesNo(question, altColour))
                    break;
                default:
                    break;
             }
        }
        return (
            <div className="usabilityQuestions">{questionsUI}</div>
        );
    }

    return (
        <div className="App">
            <Header title={usabilityContent.title}/>
            <div className="content">
                <div className="usabilityQuestionsContainer">
                    <div className="contentBlock">{usabilityContent.intro}</div>
                    {renderQuestions()}
                </div>
            </div>
            <div className="demoFtr">
                    {renderProceedButton()}
            </div>
    </div>

    );

  };
  
  export default Usability;