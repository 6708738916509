import React from "react";

const Checkbox = ( { statement, onChange } ) => {
    
    return (
        <div className="checkbox">
            <input type="checkbox" onChange={onChange}/><i>{statement}</i>
        </div>
    )
}

export default Checkbox;