let instructionsContent = {
    "title" : "Instructions",
    "intro" : "You will be shown a single card at a time as you navigate the task.  Please read the card and select the answer(s) best suited to the statement on the card.  Types of answers are described below:",
    "faqs" : [
        {"title" : "Timeline Cards", "body" : "Think about your most recent episode of self-harm. To complete CaTS-E, you will be presented with a card. This card will have a self-harm factor on it (e.g., “I felt depressed and sad”). Underneath will be 8 timepoints (before 6 months; 6 months before, 1 month before, 1 week before, 1 day before, 1 hour before, I self-harmed, afterwards). Think about the factor on the card and whether it is relevant to your most recent self-harm episode. If it is relevant, select all timepoints relevant to that card. There is a ‘not applicable’ button if the card is not relevant to your most recent self-harm episode."},
        {"title" : "Yes and No Cards", "body" : "Read the statement on these cards and select 'Yes' if you agree with the statement or 'No' if you disagree with the statement.  Choose 'not applicable' if the statement does not relate to you. The 'After I self-harmed' cards relate to what happened after your most recent episode of self-harm. Select yes, no, or not applicable"},
        {"title" : "Move to the next card", "body" : "After selecting all relevant timepoints or ‘not applicable’, press the ‘next’ button to take you to the next slide. You will work through all cards this way. After you complete CaTS-E you will be asked some questions about the task, cards, concepts, and user experience."}
    ]
};

export default instructionsContent;