import React from 'react';
import infoContent from '../content/info';
import Header from "../components/header";

import Button from "../atoms/button";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

const renderAccordianItem = (item) => {
    return (
        <AccordionItem>
            <AccordionItemHeading><AccordionItemButton>{item.title}</AccordionItemButton></AccordionItemHeading>
            <AccordionItemPanel><span dangerouslySetInnerHTML={{ __html: item.body}}></span></AccordionItemPanel>
        </AccordionItem>
    )
}

const Information = ({ session }) => {
    return (
    <div className="content">
        <Header title={infoContent.title} />
        <div className="contentBlock" style={{ fontWeight: 'bold'}} >{infoContent.gdprStatement}</div>
        <Accordion>{infoContent.faqs.map((item) => (renderAccordianItem(item)))}</Accordion>
        <div className="contentBlock" dangerouslySetInnerHTML={{ __html: infoContent.sessionStatement.replace("[[sessionID]]", session.sessionId )}}></div>
        <div className="contentBlock"><Button path="/about-you" label={infoContent.btnLabel} /></div>
    </div>
    );

  };
  
  export default Information;