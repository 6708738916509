import homeContent from "../content/home";
import React from 'react';
import parse from 'html-react-parser';

import Button from "../atoms/button";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import Header from "../components/header";


const Home = ({ session }) => {
    const renderBeginBtn = () => {
        if (session !== null && session.success === true) {
            return (
                <Button path="/info" label={homeContent.startBtnLabel} />
            );
        } 
        return (
            <p><b>Sorry but we couldn't find the survey id</b></p>
        )
    };

    const renderAccordianItem = (item) => {
        return (
            <AccordionItem>
                <AccordionItemHeading><AccordionItemButton>{item.title}</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel><p>{parse(item.body)}</p></AccordionItemPanel>
            </AccordionItem>
        )
    }

    return (
    <div className="content">
        <Header title={homeContent.title}/>
        <div className="contentBlock">{homeContent.intro}</div>
        <div className="contentBlock" dangerouslySetInnerHTML={{ __html: homeContent.participation}}></div>  
        <div className="sectionHeading">Risk of Harm:</div>
        <div className="contentBlock" dangerouslySetInnerHTML={{ __html: homeContent.risk}}></div>  
        <div className="sectionHeading">How to complete the task:</div>
        <div className="contentBlock" dangerouslySetInnerHTML={{ __html: homeContent.instructions}}></div>  
        <div className="sectionHeading">Consent:</div>
        <div className="contentBlock" dangerouslySetInnerHTML={{ __html: homeContent.declaration}}></div>  
        
            <div className="contentBlock">{homeContent.consent}</div>
            <div className="contentBlock">{ renderBeginBtn() }</div> 
    </div>

    );
  };
  
  export default Home;