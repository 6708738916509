import React, { useState, useEffect } from 'react';
import storeKeys from '../data/storeKeys';

import Cards from '../components/cards';
import Answers from '../components/answers';
import Progress from '../components/progress';

import { useNavigate } from 'react-router-dom';
import { getSurveyData } from '../functions/survey';
import { saveResults } from "../functions/session";


const Survey = ({ session }) => {
  const navigate = useNavigate();
  const [surveyData, setSurveyData] = useState({
    cards:[], 
    answers: [], 
    categories: []});

  const [currentCard, setCurrentCard] = useState( 0 );
  const [answersChanged, setAnswersChanged] = useState( 0 );
  const [currentCategory, setCurrentCategory] = useState( 0 );
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [cardStart, setCardStart] = useState();

  const setCardCookie = () => {
    window.localStorage.setItem(storeKeys.CurrentCard, currentCard);
  }

  useEffect(() => {
    if(session !== null && session.surveyId !== undefined){
        getSurveyData(session.surveyId, setSurveyData);
    }
  },[session])

  useEffect(() => {
    setCardFromCookieOnFirstLoad();
    getCurrentCategory();
  })

  useEffect(() => {
    setCardStart( Date.now() );
  },[currentCard])

  const setCardFromCookieOnFirstLoad = () => {
    if ( isFirstLoad ){
      setIsFirstLoad( false );
      let cookieCard = window.localStorage.getItem(storeKeys.CurrentCard);
      if ( cookieCard !== null ) {
        if (cookieCard === "-") navigate("/usability")
        else setCurrentCard( cookieCard * 1 );
      }
    }
    else { setCardCookie(); }
  }

  const getCurrentCategory = () => {
    let category = 0;
    for (let i = 0; i < surveyData.categories.length; i++){
      if (surveyData.categories[i].id === surveyData.cards[currentCard].category) { category = i}
    }
    setCurrentCategory(category);
  }

  const handleAnswerClick = (answerId) => {
    let newResults = selectedAnswers;

    if(answerId === "tx") {  // If user clicks not applicable then deselect all other answers.
      newResults = [];
      newResults.push( answerId )
    }
    else {
      const answersWithoutNA = selectedAnswers.filter(answer => {
        return answer !== "tx";
      });

      if(selectedAnswers.includes( answerId )) {
        const filtered = answersWithoutNA.filter(answer => {
          return answer !== answerId;
        });
        newResults = filtered;
      }
      else { 
        answersWithoutNA.push( answerId )
        newResults = answersWithoutNA;
       }
    }
    setSelectedAnswers(newResults);
    setAnswersChanged( answersChanged + 1 );
  }

  const handleNextCard = () => {

    saveResults({session: session.sessionId, statement: surveyData.cards[currentCard].id, answers: selectedAnswers, duration: (Math.round((Date.now()-cardStart)/1000))})
    let index = currentCard + 1;
    if ( index > surveyData.cards.length -1 ) window.location.href = '/usability';
    changeCard( currentCard + 1 );
  }

  const renderNextButton = () => {
    if (selectedAnswers.length === 0) {
      return (
      <div className="cardChangeBtn disabled">
        <span>Next</span>
      </div>        
    )}
    else {
      return (
      <div className="cardChangeBtn" onClick={handleNextCard}>
        <span>Next</span>
      </div>        
    )}
  }

  const changeCard = ( index ) => {
    setCurrentCard( index );
    setSelectedAnswers( [] );
  }

  const getColour = () => {
    if (surveyData.categories.length > 0) {
      return surveyData.categories[currentCategory].colour
    } 
    return "#10263b";
  }
  
  const getAnswerType = () => {
    if (surveyData.cards.length > 0) {
      return surveyData.cards[currentCard].answerType
    } 
    return "";
  }

  const getCurrentCard = () => {
    if (surveyData.cards.length > 0) {
      return surveyData.cards[currentCard]
    } 
    return {id: 0, statement:""};    
  }

  const getCategory = () => {
    if (surveyData.categories.length > 0) {
      return surveyData.categories[currentCategory]
    } 
    return {id: 0, description:""};  
  }

  const getInstruction = () => {
    let instruction = "Please select all timepoints relevant to your last self-harm episode. You can select one, multiple, or all timepoints (including 'I self-harmed'), or not applicable"
    switch (getAnswerType()) {
      case "confirm": 
        instruction = "Please select Yes or No, depending on whether the card is relevant to what happened after your last self-harm episode";
        break;
      default: 
        break;
    }

    return instruction;
  }

  return (
      <div className="App">
        <header className="surveyHdr">
          <Progress total={surveyData.cards.length} index={currentCard + 1} colour={getColour()} />
          {renderNextButton()}
        </header>
        <div className="content">
          <div className="instruction">{getInstruction()}</div>
            <Cards 
            category={getCategory()}
            card={ getCurrentCard() } 
            colour= {getColour()}
           />

          <Answers answers={ surveyData.answers } answerType={getAnswerType()} selectedAnswers={ selectedAnswers } handleAnswerClick={ handleAnswerClick } /> 
        </div>

      </div>
    );
  };

  export default Survey;