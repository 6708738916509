import { API_URL } from "./apiHelper.js";

export async function getDemographicOptions(surveyId, passBack) {
    const response = await fetch( API_URL ('/demographics/' + surveyId ) );
    const data = await response.json();
    if(data !== undefined) {
        let demographics = [];
        let lastGroup = data.body[0].title;
        let options = [];
        let idCount = 0;
        for (let i = 0 ; i < data.body.length ; i++)
        {
            if (lastGroup !== data.body[i].title) {
                demographics.push({id: idCount, label: lastGroup, options: options});
                options = [];
                lastGroup = data.body[i].title
                idCount++;
            }
            options.push({value: data.body[i].key, label:data.body[i].text})
        }
        demographics.push({id: idCount, label: lastGroup, options: options});
        passBack(demographics);
    }
}


export async function postDemographicResponses(data, callBack) {
    try {
        const response = await fetch( API_URL( '/demographics' ), {
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-Type' : 'application/json' 
            },

            body : JSON.stringify(data)
        })
        callBack(response.status === 200);
        
    } catch (err) {
        console.log("failed to save demographics", err)
    }   
}