import { API_URL } from "./apiHelper.js";

export async function getUsabilityQuestionaire(surveyId, passBack) {

    const response = await fetch( API_URL( '/usability/' + surveyId ) );
    const data = await response.json();

    let usabilityData = {questions: [], responses: []}
    if(data !== undefined) {
        
        usabilityData.questions = data.body.questions;
        usabilityData.responses = data.body.responses;
    }
    
    passBack(usabilityData);
}

export async function getUsabilityQuestion(surveyId, questionId, passBack) {

    const response = await fetch( API_URL('/usability/' + surveyId + '/' + questionId ) );
    const data = await response.json();

    let usabilityData = {questions: [], responses: []}
    if(data !== undefined) {
        
        usabilityData.questions = data.body.questions;
        usabilityData.responses = data.body.responses;
    }
    passBack(usabilityData);
}

export async function postUsersResponses(data, callBack) {
    try {
        const response = await fetch( API_URL( '/usability' ), {
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-Type' : 'application/json' 
            },

            body : JSON.stringify(data)
        })
        callBack(response.status === 200);
        
    } catch (err) {
        console.log("failed to save usability responses", err)
    }   
}
