import storeKeys from "../data/storeKeys";
import { API_URL } from "./apiHelper.js";


export function createSessionCookie(session) {
    window.localStorage.setItem(storeKeys.SessionObj, objectToSessionString(session));
}

export function checkClearSession() {
    const queryParams = new URLSearchParams(window.location.search);
    let clear = queryParams.get('clear');
    if (clear === "1") {
        window.localStorage.removeItem( storeKeys.SessionObj );
        window.localStorage.removeItem( storeKeys.CurrentCard );
        return true;
    }
    return false;
}

export function initialiseSurvey(callBack, isMobile, isTablet) {
    const queryParams = new URLSearchParams(window.location.search);
    let qs = queryParams.get('s');

    if (qs === null || qs === undefined || qs === "") {
        return formatSessionObjError("No survey id in Url");
    }

    let device = "desktop";
    if (isMobile) device = "mobile"
    if (isTablet) device = "tablet"

    console.log(device)
    createSession(qs, device, callBack);
}

export async function createSession(surveyId, device,  callBack) {
    const response =  await fetch( API_URL( '/session/create' ),{
        method: 'POST',
        headers: {
           'Accept' : 'application/json',
           'Content-Type' : 'application/json' 
       },

       body : JSON.stringify({survey: surveyId, 'device': device})            
    })

    const result  = await response.json();

    if (response.status === 201)
    {
        callBack(formatSessionObj(
            result.sessionId,
            result.surveyId,
            Date.parse(result.sessionStart)
        ));
    }
    return true;  
}

export function saveResults(data) {
    try {
          fetch( API_URL('/session/result'),{
              method: 'POST',
              headers: {
                'Accept' : 'application/json',
                'Content-Type' : 'application/json' 
            },
    
            body : JSON.stringify(data)            
          })
          .then(res => res.json())
          .then(data => {}
          )
      }catch(error){
        console.log("Failed to save results", data)
      }
      return true;  
}

export function formatSessionObjError(error) {
    return {
        success  : false,
        'error'  : error
    }
}

export function formatSessionObj(sessionId, surveyId, sessionStart) {
    return {
        success      : true,
        sessionId    : sessionId,
        surveyId     : surveyId,
        sessionStart : sessionStart
    }
}

export function sessionStringToObject(sessionStr) {
    let sessionArr = sessionStr.split("|");
    if (sessionArr.length !== 3 ) { 
        return {success: false, 'error': 'Not a valid session string'}
    }

    return formatSessionObj(sessionArr[0], sessionArr[1], sessionArr[2]);
}

export function objectToSessionString(sessionJson){
    return sessionJson.sessionId + "|" + sessionJson.surveyId + "|" + sessionJson.sessionStart;
}

export function sessionCookieExists() {
    return (window.localStorage.getItem(storeKeys.SessionObj) !== null);
}

export function getSessionObj() {
    if (!sessionCookieExists()) return {sucess:false, error: 'no session cookie'};
    return sessionStringToObject(window.localStorage.getItem(storeKeys.SessionObj));
}

export const endSession = (session) => {
      try {

            fetch( API_URL( '/session/end' ),{
                method: 'POST',
                headers: {
                  'Accept' : 'application/json',
                  'Content-Type' : 'application/json' 
              },
              body : JSON.stringify({'session':session})            
            })
            .then(res => res.json())
            .then(data => {console.log(data)}
            )
        }catch(error){
          console.log("Failed to end session", session)
        }
        return true;  
  }