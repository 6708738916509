import React from 'react';
import instructionsContent from '../content/instructions';
import Header from "../components/header";

import Button from "../atoms/button";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

const renderAccordianItem = (item) => {
    return (
        <AccordionItem>
            <AccordionItemHeading><AccordionItemButton>{item.title}</AccordionItemButton></AccordionItemHeading>
            <AccordionItemPanel><span dangerouslySetInnerHTML={{ __html: item.body}}></span></AccordionItemPanel>
        </AccordionItem>
    )
}

const Instructions = ({ session }) => {
    console.log(session)
    return (
    <div className="content">
        <Header title={instructionsContent.title} />
        <div className="contentBlock">{instructionsContent.intro}</div>

        <Accordion>{instructionsContent.faqs.map((item) => (renderAccordianItem(item)))}</Accordion>
        <div className="contentBlock"><Button path="/survey" label="Continue" /></div>
    </div>
    );

  };
  
  export default Instructions;